/*------------------------------------------------------------------
Import styles
------------------------------------------------------------------*/

import './../styles/style.scss';

import * as PIXI from 'pixi.js'


(function () {
    const el = document.querySelector('#parallax');
    setInterval(() => el.classList.add('active'), 500);
})();


(function () {

    // let type = 'WebGL'
    // if (!PIXI.utils.isWebGLSupported()) type = 'canvas';

    // let app = new PIXI.Application({
    //     width: 1920,
    //     height: 1080,
    //     transparent: true,
    //     backgroundColor: 0xffffff
    // });

    // let illu = document.getElementById('illu');

    // illu.insertBefore(app.view, illu.firstChild);

    // function createRipples(channel, freq) {
    //     let cvo = new PIXI.utils.CanvasRenderTarget(500, 500),
    //         cv = cvo.canvas,
    //         ctx = cvo.context,
    //         grd = ctx.createLinearGradient(0, 0, 500, 0),
    //         grd2 = ctx.createLinearGradient(0, 0, 0, 500),
    //         col = channel ? 'red' : 'green';
    //     grd.addColorStop(0, 'black');
    //     grd.addColorStop(0.5, col);
    //     grd.addColorStop(1, 'black');
    //     grd2.addColorStop(0, 'black');
    //     grd2.addColorStop(0.5, 'rgba(0,0,0,0)');
    //     grd2.addColorStop(1, 'black');
    //     ctx.fillStyle = grd;
    //     ctx.save();
    //     ctx.scale(1 / freq, 1);
    //     for (let i = 0; i < freq; i++) {
    //         ctx.fillRect(0, 0, 500, 500);
    //         ctx.translate(500, 0);
    //     }
    //     ctx.restore();
    //     ctx.scale(1, 1 / freq);
    //     ctx.fillStyle = grd2;
    //     for (let i = 0; i < freq; i++) {
    //         ctx.fillRect(0, 0, 500, 500);
    //         ctx.translate(0, 500);
    //     }
    //     let spr = new PIXI.TilingSprite(PIXI.Texture.from(cv), app.renderer.width, app.renderer.height);
    //     spr.blendMode = PIXI.BLEND_MODES.ADD;
    //     return spr;
    // }

    // let container = new PIXI.Container();
    // app.stage.addChild(container);

    // let dContainer = new PIXI.Container(),
    //     dRenderTexture = PIXI.RenderTexture.create(app.renderer.width, app.renderer.height),
    //     ripplesR = createRipples(0, 6),
    //     ripplesG = createRipples(1, 7),
    //     displacementSprite = new PIXI.Sprite(dRenderTexture);
    // dContainer.addChild(ripplesR);
    // dContainer.addChild(ripplesG);
    // app.renderer.render(dContainer, dRenderTexture);

    // let img_a = PIXI.Sprite.from('found-creative-website.jpg');
    // img_a.x = -10;
    // img_a.width = app.renderer.width + 10;
    // img_a.height = app.renderer.height;

    // container.addChild(img_a);
    // container.addChild(displacementSprite);

    // let blurFilter = new PIXI.filters.BlurFilter(15, 3, 1);
    // dContainer.filters = [blurFilter];

    // let displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
    // img_a.filters = [displacementFilter];
    // displacementFilter.scale.x = 50;
    // displacementFilter.scale.y = 50;

    // let xformR = new PIXI.Transform(),
    //     xformG = new PIXI.Transform(),
    //     phase = 0;
    // xformR.position.set(app.renderer.width * 0.5, app.renderer.height * 0.25);
    // xformG.position.set(app.renderer.width * 0.5, app.renderer.height * 0.75);

    // app.ticker.add(function () {
    //     xformR.rotation += 0.01;
    //     xformG.rotation -= 0.0045;
    //     ripplesR.tileTransform = xformR;
    //     ripplesG.tileTransform = xformG;
    //     app.renderer.render(dContainer, dRenderTexture);
    //     phase += 0.025;
    // });


    
    // let ratio = 1.777777778;

    // function resize() {
    //     let w, h;
    //     // console.clear();
    //     // console.log(`innerWidth:${window.innerWidth}`)
    //     // console.log(`innerHeight:${window.innerHeight}`)
    //     // console.log(`calculated ratio:${window.innerWidth / window.innerHeight >= ratio}`)

    //     if (window.innerWidth / window.innerHeight >= ratio) {
    //         // console.log('option 1');
    //         w = window.innerWidth ;
    //         h = window.innerWidth / ratio;
    //     } else {
    //         // console.log('option 2');
    //         w = window.innerHeight * ratio;
    //         h = window.innerHeight;
    //     }
    //     app.renderer.view.style.width = w + 'px';
    //     app.renderer.view.style.height = h + 'px';
    // }

    // window.onresize = resize;

    // resize();

})();

